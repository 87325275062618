import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';

import { AnchorBarBlockViewModel } from './AnchorBarModule.types';
import { getComponentProps } from '@/utils/vueUtils';
import SizeBreakpoints from '@/common/interfaces/SizeBreakpoints';

import CtaButton from '@/components/CtaButton/CtaButton.vue';
import Chevron from '@/assets/icons/chevron.svg?inline';

import { SET_FROM_ANCHOR } from '@/store/actions';
import LinkItem from '@/common/interfaces/LinkItem';

import { throttled } from '@/utils/commonUtils';

import OverlayMixin from '@/mixins/OverlayMixin';
import { mixins } from 'vue-class-component';

import { currentUri, windowOffset, windowSize, anchorBarLinks } from '@/store/getters';
import { CHANGE_WINDOW_SIZE, M_SET_HAS_ANCHOR_BAR } from '@/store/mutations';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

@Component({
  props: getComponentProps<AnchorBarBlockViewModel>({
    Header: 'Alaska',
    Ctas: [{ Title: 'Brochure', Link: '#' }],
  }),
  components: {
    CtaButton,
    Chevron,
  },
})
export default class AnchorBarModule extends mixins(OverlayMixin) {
  readonly p!: AnchorBarBlockViewModel;

  isOpen = false;
  offsetTop = null;

  @Ref('navbar') readonly navbar!: HTMLElement;
  @Ref('anchor-links') readonly anchorLinks!: HTMLElement;

  @Getter(windowSize) windowSize!: SizeBreakpoints;
  @Getter(windowOffset) windowOffset!: number;
  @Getter(currentUri) currentUri!: string;
  @Getter(anchorBarLinks) anchorBarLinks!: LinkItem[];

  @Action(SET_FROM_ANCHOR) setLink!: (link: string) => void;

  @Watch('isOpen')
  function(isOpen: boolean) {
    this.openCloseOverlay(isOpen, this.anchorLinks);
  }

  get isSticky() {
    if (this.navbar) {
      return this.windowOffset >= this.offsetTop;
    }

    return this.windowOffset < 0;
  }

  getOffsetTop() {
    if (this.navbar) {
      this.offsetTop = this.navbar.offsetTop;
    } else {
      return;
    }
  }

  get rootClass() {
    return {
      'anchor-bar': true,
      expanded: this.isOpen,
    };
  }

  scrollToLink(link: string, text: string) {
    this.isOpen = false;
    this.setLink(link);
    SegmentElement.sendEvent('subNavigationClicked', {text, url: document.URL})
    this.$root.$emit('clickedNavBar', window.pageYOffset);
  }

  @Watch('windowSize')
  orientationChange() {
    if (this.windowSize === 'xs' || this.windowSize === 'sm' || this.windowSize === 'md') {
      if (window.matchMedia('(orientation: landscape)').matches) {
        this.getOffsetTop;
      }
    } else {
      return;
    }
  }

  @Watch('windowSize')
  lgCloseOverlay() {
    if (window.outerWidth >= 1024) {
      this.isOpen = false;
    }
  }

  getCurrentLink() {
    let uri = this.currentUri;
    if (this.anchorBarLinks) {
      var currentLinkTitle = this.anchorBarLinks.filter(function (link) {
        if (link.Link === uri) {
          return link.Title;
        } else {
          return;
        }
      });
    } else {
      return this.p.Header;
    }
    return currentLinkTitle[0].Title;
  }

  get linksWithoutSlash() {
    return this.anchorBarLinks.map(link => {
      let itineraryName = window.location.pathname.replace(/\/$/, '');
      return {
        ...link,
        fullLink: itineraryName + link.Link
      }
    })
  }

  get Ctas() {
    return this.p.Ctas.map(button => {
      if (button.Link && button.Link.includes('#')) {
        return {
          ...button,
          Link: window.location.pathname.replace(/\/$/, '') + button.Link
        }
      }
      return button
    })
  }

  @Mutation(M_SET_HAS_ANCHOR_BAR) setHasAnchorBar: () => void;

  @Mutation(CHANGE_WINDOW_SIZE) changeWindowSize: (width: number) => void;

  mounted() {
    this.setHasAnchorBar();

    Vue.nextTick(() => {
      this.changeWindowSize(window.innerWidth);
    });

    this.getOffsetTop();

    window.addEventListener('resize', () => {
      throttled(500, this.getOffsetTop);
    });
  }
}
