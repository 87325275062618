import Vue from 'vue';
import Component from 'vue-class-component';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Mutation } from 'vuex-class';
import { OPEN_OVERLAY, CLOSE_OVERLAY } from '@/store/mutations';

@Component({})
export default class OverlayMixin extends Vue {
  @Mutation(OPEN_OVERLAY)
  openOverlay!: () => void;

  @Mutation(CLOSE_OVERLAY)
  closeOverlay!: () => void;

  openCloseOverlay(isOpen: boolean, scrollableElement?: any) {
    if (isOpen) {
      this.openOverlay();
      disableBodyScroll(scrollableElement);
    } else {
      this.closeOverlay();
      clearAllBodyScrollLocks();
    }
  }
}
